@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.root {
  margin-bottom: 64px;

  @include breakpoint($sm) {
    margin-bottom: 40px;
  }

  @include breakpoint($lg) {
    overflow: hidden;
    margin-bottom: 0;
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  @include breakpoint($lg) {
    height: 400px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  padding: 24px;
  border-radius: 16px;
  box-shadow: $bs1;

  @include breakpoint($sm) {
    flex-direction: row;
  }
}

.icon {
  position: relative;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-bottom: 16px;

  @include breakpoint($sm) {
    width: 48px;
    height: 48px;
    margin-right: 24px;
    margin-bottom: 0;
  }
}

.text_block {
  font-size: 16px;
  line-height: 24px;
}

.title {
  margin-bottom: 16px;
  font-weight: 700;

  @include breakpoint($sm) {
    font-size: 18px;
  }
}

.text {
  color: $secondary;
  font-weight: 400;
}

.btn_prev {
  position: relative;
  width: 44px;
  height: 2.3px;
  border-radius: 5px;
  background: $accent-default;

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -2px;
    display: block;
    width: 15px;
    height: 2px;
    border-radius: 5px;
    background: $accent-default;
    transition: all 0.3s ease;
    transform: rotate(-45deg);
    transform-origin: center;
  }

  &::after {
    content: '';
    position: absolute;
    top: 5px;
    left: -2px;
    display: block;
    width: 15px;
    height: 2px;
    border-radius: 5px;
    background: $accent-default;
    transition: all 0.3s ease;
    transform: rotate(45deg);
    transform-origin: center;
  }
}

.btn_next {
  position: relative;
  width: 40px;
  height: 2px;
  border-radius: 5px;
  background: $accent-default;

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    right: -2px;
    display: block;
    width: 15px;
    height: 2px;
    border-radius: 5px;
    background: $accent-default;
    transition: all 0.3s ease;
    transform: rotate(45deg);
    transform-origin: center;
  }

  &::after {
    content: '';
    position: absolute;
    top: 5px;
    right: -2px;
    display: block;
    width: 15px;
    height: 2px;
    border-radius: 5px;
    background: $accent-default;
    transition: all 0.3s ease;
    transform: rotate(-45deg);
    transform-origin: center;
  }
}

.list {
  @include breakpoint($lg) {
    position: absolute;
    left: 0;
    width: 100%;
  }

  &._disabled-next {
    .btn_next {
      &::before {
        top: 0;
        right: 0;
        transform: rotate(0);
      }

      &::after {
        top: 0;
        right: 0;
        transform: rotate(0);
      }
    }
  }

  &._disabled-prev {
    .btn_prev {
      &::before {
        top: 0;
        left: 0;
        transform: rotate(0);
      }

      &::after {
        top: 0;
        left: 0;
        transform: rotate(0);
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include breakpoint($sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint($lg) {
    grid-template-columns: repeat(4, 390px);
    column-gap: 24px;
    transition: transform 1s ease;
  }

  @include breakpoint($lg2) {
    grid-template-columns: repeat(4, 456px);
  }

  &._transform-next {
    transform: translateX(calc(-390px - 24px));

    @include breakpoint($lg2) {
      transform: translateX(calc(-456px - 24px));
    }
  }

  &._transform-prev {
    transform: translateX(0);
  }
}

.btn_block {
  display: none;
  gap: 16px;
  justify-content: center;
  margin-top: 24px;

  @include breakpoint($lg) {
    display: flex;
  }

  button {
    height: 40px;
  }
}
