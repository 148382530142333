/* stylelint-disable declaration-no-important */
@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.root {
  margin-bottom: 96px;

  @include breakpoint($sm) {
    margin-bottom: 80px;
  }

  @include breakpoint($lg) {
    margin-bottom: 144px;
  }
}

.subtitle {
  margin-top: 16px;
  color: $gray;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  @include breakpoint($lg) {
    margin-top: 32px;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin-top: 40px;

  @include breakpoint($lg) {
    flex-direction: row;
    margin-top: 48px;
  }
}

.item {
  width: 100%;

  @include breakpoint($lg) {
    width: calc(100% / 4);
  }
}

.card {
  padding: 40px 32px;
  border-radius: 16px;
  box-shadow: $bs1;
}

.top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.icon {
  position: relative;
  width: auto;
  min-width: 40px;
  height: 40px;
  margin-bottom: 40px;
}

.icon_star {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  color: $yellow;
}

.star {
  display: flex;
  align-items: flex-end;
  color: $text;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
}

.review {
  color: $accent-default;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.btn {
  margin-top: 8px;

  svg {
    width: 48px !important;
    height: 32px !important;
    color: $black-light !important;
  }
}

.card_title {
  margin-top: 32px;
  padding: 0 32px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.card_subtitle {
  padding: 0 32px;
  color: $secondary;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
