/* stylelint-disable declaration-no-important */

@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';
@import 'src/styles/variables/mixins';

.root {
  margin-bottom: 96px;

  @include breakpoint($sm) {
    margin-bottom: 80px;
  }

  @include breakpoint($lg) {
    margin-bottom: 144px;
  }
}

.subtitle {
  margin-bottom: 16px;
  color: $secondary;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  @include breakpoint($lg) {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
}

._subtitle_padding {
  margin-bottom: 48px;
}

.panel_title {
  color: $text;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  @include transition(color);

  @include breakpoint($lg) {
    font-size: 24px;
    line-height: 32px;
  }
}

.panel_subtitle {
  margin-top: 16px;
  color: $secondary;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  @include transition(color);
}

.panel_price {
  margin-top: 16px;
  color: $accent-active;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  @include transition(color);

  @include breakpoint($md) {
    margin-top: 24px;
    font-size: 24px;
    line-height: 32px;
  }

  @include breakpoint($lg) {
    margin-top: 32px;
  }
}

.panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 24px;
  border: 1px solid $accent-default;
  border-radius: 16px;
  @include transition(color, background-color);

  @include breakpoint($sm) {
    width: calc((100% - 24px) / 2);
  }

  &:hover {
    background-color: $accent-default;
    cursor: pointer;

    .btn {
      background-color: $black-light;
    }

    .panel_title,
    .panel_subtitle,
    .panel_price {
      color: $white;
    }
  }
}

.panel_list {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  width: 100%;
}

.btn_group {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  justify-content: space-between;
  margin-top: 16px;

  @include breakpoint($md) {
    margin-top: 24px;
  }

  @include breakpoint($lg) {
    flex-direction: row;
    margin-top: 32px;
  }
}

.btn_work svg {
  width: 48px;
  height: 32px;
}

.btn_work {
  &:hover {
    color: $white !important;

    svg {
      color: $white !important;
    }
  }
}
