@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.root {
  display: grid;
}

.form {
  margin: 40px 0;
  padding: 24px 16px 0;
  border-radius: 16px;
  background-color: $white;
  box-shadow: $bs1;

  @include breakpoint($sm) {
    margin-bottom: 80px;
    padding: 40px 40px 0;
  }

  @include breakpoint($md) {
    padding: 40px 56px 0;
  }
}

._template-1 {
  @include breakpoint($md) {
    grid-template-columns: 44% 53%;
    column-gap: 24px;
  }

  @include breakpoint($lg) {
    grid-template-columns: 44% 49%;
    column-gap: 98px;
  }

  .form {
    @include breakpoint($md) {
      margin-top: -72px;
      margin-bottom: 0;
    }
  }
}

._template-2 {
  @include breakpoint($lg) {
    grid-template-columns: 39% 59%;
    column-gap: 24px;
  }

  .form {
    @include breakpoint($lg) {
      margin-top: -72px;
      margin-bottom: 0;
    }
  }
}

._template-3 {
  @include breakpoint($md) {
    grid-template-columns: 44% 53%;
    column-gap: 24px;
  }

  @include breakpoint($lg) {
    grid-template-columns: 44% 49%;
    column-gap: 98px;
  }

  .form {
    @include breakpoint($sm) {
      max-width: 448px;
      margin-right: auto;
      margin-left: auto;
    }

    @include breakpoint($md) {
      margin-top: -72px;
      margin-bottom: 0;
    }
  }
}

.content {
  margin-top: 40px;

  @include breakpoint($sm) {
    max-width: 70%;
  }

  @include breakpoint($md) {
    max-width: 100%;
    margin-top: 64px;
  }
}

.title {
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;

  @include breakpoint($sm) {
    margin-bottom: 40px;
  }

  @include breakpoint($md) {
    margin-bottom: 48px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  @include breakpoint($lg) {
    row-gap: 56px;
  }
}

.list_item {
  display: flex;
  align-items: flex-start;
}

.item_icon {
  position: relative;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 16px;

  @include breakpoint($sm) {
    width: 56px;
    height: 56px;
    margin-right: 32px;
  }
}

.item_title {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  @include breakpoint($sm) {
    font-size: 22px;
    line-height: 32px;
  }
}

.item_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  @include breakpoint($sm) {
    font-size: 16px;
    line-height: 24px;
  }
}
