@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @include breakpoint($sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint($lg) {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
}
