/* stylelint-disable declaration-no-important */
@import 'src/styles/variables/colors';
@import 'src/styles/variables/mixins';
@import 'src/styles/variables/breakpoints';

.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px !important;
  border-radius: 12px;
  background-color: transparent;
  color: rgba($black, 0.4);
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  @include transition(color, background-color);

  @include breakpoint($md) {
    padding: 12px 24px !important;
    font-size: 16px;
    line-height: 16px;
  }

  &:hover {
    color: $black;
  }

  &._isActive {
    background-color: $accent-default;
    color: $white;
  }
}

._fullWidth {
  width: 100%;
}
