@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.root {
  display: grid;
}

.content {
  margin-top: 40px;

  @include breakpoint($md) {
    max-width: 100%;
  }

  @include breakpoint($lg) {
    margin-top: 60px;
  }
}

.header {
  margin: 0;
  margin-bottom: 16px;
  color: $text;
  font-weight: 700;
  font-size: 27px;
  line-height: 32px;
  text-align: left;

  @include breakpoint($md) {
    font-size: 32px;
    line-height: 36px;
  }

  @include breakpoint($lg) {
    font-size: 48px;
    line-height: 48px;
  }
}

.form {
  margin-bottom: 120px;
  padding: 24px 16px 0;
  border-radius: 16px;
  background-color: $white;
  box-shadow: $bs1;

  @include breakpoint($sm) {
    margin-top: 40px;
  }

  @include breakpoint($md) {
    padding: 32px 32px 0;
  }

  @include breakpoint($lg) {
    margin-top: 60px;
    padding: 40px 56px 0;
  }
}

._template-1 {
  @include breakpoint($md) {
    grid-template-columns: 44% 53%;
    column-gap: 24px;
  }

  @include breakpoint($lg) {
    grid-template-columns: 44% 49%;
    column-gap: 98px;
  }

  .content {
    @include breakpoint($sm) {
      max-width: 70%;
    }

    @include breakpoint($md) {
      max-width: 100%;
    }
  }
}

._template-2 {
  @include breakpoint($sm) {
    grid-template-columns: 51% 45%;
    column-gap: 24px;
  }

  @include breakpoint($lg) {
    grid-template-columns: 61% 32%;
    column-gap: 98px;
  }

  .form {
    @include breakpoint($md) {
      margin-bottom: 120px;
    }
  }
}

._pageType-location {
  .header {
    @include breakpoint($lg) {
      font-size: 32px;
      line-height: 36px;
    }
  }
}

.subtitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;

  @include breakpoint($md) {
    font-size: 18px;
  }

  @include breakpoint($lg) {
    font-size: 20px;
    line-height: 32px;
  }
}

.price {
  display: inline-block;
  padding-left: 8px;
  color: $accent-default;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;

  @include breakpoint($md) {
    font-size: 24px;
    line-height: 28px;
  }

  @include breakpoint($lg) {
    font-size: 28px;
    line-height: 32px;
  }
}

.text {
  margin-top: 24px;

  @include breakpoint($lg) {
    margin-top: 64px;
  }

  p {
    padding-bottom: 8px;
    color: $text;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    @include breakpoint($md) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0 40px;

  @include breakpoint($sm) {
    flex-flow: row wrap;
    margin: 32px 0 24px;
  }

  @include breakpoint($lg) {
    flex-wrap: nowrap;
    max-width: 650px;
  }
}

.list_item {
  display: flex;
  flex-basis: calc(100% / 3);
  align-items: center;
}

.item_round {
  position: relative;
  z-index: 1;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 56px;
  height: 56px;
  background-color: transparent;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    box-sizing: border-box;
    width: 56px;
    height: 56px;
    border: 2px solid $main-dark-green;
    border-radius: 50%;
  }

  .item_number {
    padding-left: 0;
  }

  &._isCLip {
    justify-content: flex-start;

    &::after {
      clip-path: inset(0 5px 0 0);
    }

    .item_number {
      padding-left: 20px;
    }
  }
}

.item_text {
  margin-left: 8px;
}

.button {
  display: flex;
  justify-content: center;
  width: 100%;

  @include breakpoint($sm) {
    justify-content: flex-start;
  }
}

.desc {
  margin-top: 16px;
  margin-bottom: 24px;
  color: $secondary;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  @include breakpoint($sm) {
    text-align: left;
  }
}
