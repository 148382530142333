/* stylelint-disable declaration-no-important */
// stylelint-disable no-descending-specificity
@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.wrapper {
  overflow: hidden;
  width: 100%;
}

.container {
  display: flex;

  &._transition-none {
    transition: none;
  }

  &._transition-transform {
    transition: transform 1s ease;
  }
}

.container__full {
  display: flex;
  height: 148px;
  margin-bottom: 16px;

  @include breakpoint($sm) {
    height: 159px;
  }

  @include breakpoint($md) {
    height: 216px;
  }

  &._transform-next_full {
    transform: translateX(calc(-100%));
  }

  &._transform-prev_full {
    transform: translateX(calc(2 * (-100%)));
  }

  &._transform-default_full {
    transform: translateX(0%);
  }
}

.slot__full {
  flex: 1 0 100%;
  flex-basis: 100%;
  overflow: hidden;
  border-radius: 4px;
}

.container__preview {
  display: flex;
  height: 40px;

  @include breakpoint($sm) {
    height: 48px;
  }

  @include breakpoint($md) {
    height: 64px;
  }

  &._transform-next {
    transform: translateX(calc(-100% / 4 - 8px));
  }

  &._transform-prev {
    transform: translateX(calc(2 * (-100% / 4 - 8px)));
  }

  &._transform-default {
    transform: translateX(0%);
  }
}

.slot__preview {
  flex: 1 0 100%;
  flex-basis: calc(25% - 8px * 3 / 4);
  overflow: hidden;
  margin-right: 8px;
  border-radius: 4px;
  cursor: pointer;

  :global(.yt-lite) {
    pointer-events: none;
  }
}

.container__modal {
  display: flex;
  height: auto;
  max-height: calc(100vh - 160px - 50px);
  margin-bottom: 16px;

  @include breakpoint($sm) {
    max-height: calc(100vh - 160px - 24px - 56px - 32px - 36px);
  }

  &._transform-next_full {
    transform: translateX(calc(-100%));
  }

  &._transform-prev_full {
    transform: translateX(calc(2 * (-100%)));
  }

  &._transform-default_full {
    transform: translateX(0%);
  }
}

.slot__modal {
  flex: 1 0 100%;
  flex-basis: 100%;
  overflow: hidden;
  border-radius: 4px;

  .image {
    cursor: default;
  }

  img {
    display: block;

    // width: auto;
    object-fit: contain;
    margin-right: auto;
    margin-left: auto;
  }

  :global(.yt-lite) {
    height: auto;
  }
}

.arrow {
  padding: 6px 8px !important;
  background-color: transparent !important;

  &:hover {
    background-color: transparent !important;

    svg {
      color: $accent-default;
    }
  }

  svg {
    color: $secondary;
  }
}

.btn_block {
  display: flex;
  justify-content: flex-end;
}

.arrow__prev {
  transform: rotate(-180deg);
}

.arrow__next {
  padding-right: 0;
}

.slide,
.video {
  height: 100%;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
