@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.divider {
  width: 100%;
  height: 1px;
  background-color: $secondary2;
}

.inform {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 16px;
}

.name {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.time {
  color: $accent-default;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.price {
  color: $secondary;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.root {
  box-sizing: border-box;
  max-width: calc(100vw - 16px - 16px);
  padding: 24px;
  border: 1px solid transparent;
  border-radius: 16px;
  box-shadow: $bs1;

  @include breakpoint($lg) {
    max-width: calc((1217px - 24px - 24px) / 3);
  }

  @include breakpoint($lg2) {
    max-width: calc((1416px - 24px - 24px) / 3);
  }
}
