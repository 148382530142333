@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';
@import 'src/styles/variables/mixins';

.root {
  padding: 24px;
  border: 1px solid transparent;
  border-radius: 16px;
  box-shadow: $bs1;
  cursor: pointer;
  @include transition(border-color);

  &:hover {
    border-color: $accent-default;
  }
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.title {
  margin-right: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  @include breakpoint($lg) {
    font-size: 18px;
  }
}

.icon {
  position: relative;
  flex-shrink: 0;
  width: 14px;
  height: 14px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $accent-default;
    transition: 0.35s;
  }

  &::before {
    transform: translateY(-50%);
  }

  &::after {
    transform: translateY(-50%) rotate(90deg);
  }
}

.description {
  overflow: hidden;
  color: $secondary;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  opacity: 0;
  transition: all 0.3s ease-out;

  & ul {
    padding-left: 24px;
  }
}

._root___open {
  .title {
    color: $accent-default;
  }

  .description {
    margin-top: 8px;
    opacity: 1;

    @include breakpoint($lg) {
      margin-top: 16px;
    }
  }

  .icon {
    &::before {
      opacity: 0;
      transform: translateY(-50%) rotate(-90deg);
    }

    &::after {
      transform: translateY(-50%) rotate(0);
    }
  }
}
