@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.root {
  position: relative;
  width: 100%;
}

.background_image {
  position: absolute;
  width: 100%;
}

._size-large {
  min-height: 592px;

  .background_image {
    height: 728px;

    @include breakpoint($sm) {
      height: 592px;
    }
  }
}

._size-medium {
  min-height: 592px;

  .background_image {
    height: 728px;

    @include breakpoint($sm) {
      height: 584px;
    }

    @include breakpoint($lg) {
      height: 592px;
    }
  }
}

._size-small {
  min-height: 587px;

  .background_image {
    height: 728px;

    @include breakpoint($sm) {
      height: 584px;
    }

    @include breakpoint($lg) {
      height: 516px;
    }
  }
}

.background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 80%;
  background: linear-gradient(90deg, #ededed 42.71%, rgb(255 255 255 / 0%) 100%);
}
