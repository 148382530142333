@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.root {
  margin-bottom: 40px;

  @include breakpoint($md) {
    margin-bottom: 104px;
  }
}

.subtitle {
  color: $secondary;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  @include breakpoint($lg) {
    font-size: 18px;
  }
}
