@import 'src/styles/variables/colors';
@import 'src/styles/variables/mixins';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.root {
  padding: 48px 0;

  @include breakpoint($sm) {
    padding: 40px 0;
  }

  @include breakpoint($md) {
    padding: 56px 0;
  }

  @include breakpoint($lg) {
    padding: 96px 0;
  }
}

._isHavePadding {
  margin-bottom: 96px;
  padding: 0;

  @include breakpoint($sm) {
    margin-bottom: 80px;
  }

  @include breakpoint($lg) {
    margin-bottom: 144px;
  }

  p {
    margin-bottom: 24px;
  }
}

.title {
  padding-bottom: 32px;

  @include breakpoint($sm) {
    padding-bottom: 56px;
  }

  @include breakpoint($lg) {
    padding-bottom: 64px;
  }
}

.grid {
  display: grid;
}

._column-two {
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;

  @include breakpoint($sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint($lg) {
    gap: 32px;
  }
}

._column-three {
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;

  @include breakpoint($sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint($md) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include breakpoint($lg) {
    gap: 32px;
  }
}
