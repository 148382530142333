@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.card {
  box-sizing: border-box;
  padding: 24px;
  border-radius: 16px;
  box-shadow: $bs1;

  @include breakpoint($sm) {
    flex-basis: 50%;
    min-height: 252px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.name {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;

  @include breakpoint($sm) {
    font-size: 18px;
  }
}

.date {
  color: $secondary;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.stars {
  display: flex;
  gap: 4px;
}

.star {
  width: 16px;
  height: 16px;
  color: $secondary2;
}

._active {
  color: $yellow;
}

.logo {
  width: 32px;
  height: 32px;
  color: #17564a;
}

.text {
  overflow: hidden;
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  transition: max-height 0.5s ease-out;

  @include breakpoint($sm) {
    margin-top: 24px;
    font-size: 16px;
    line-height: 24px;
  }
}

.btn_more {
  margin-top: 8px;
}

.icon {
  display: flex;
  align-items: center;
  transform: rotate(90deg);

  svg {
    width: 16px;
    height: 16px;
  }
}

._open {
  transform: rotate(-90deg);
}

.service_btn {
  margin-top: 8px;

  a {
    font-size: 14px;
    line-height: 16px;
  }
}
