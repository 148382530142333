@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.faq {
  margin-bottom: 96px;

  @include breakpoint($sm) {
    margin-bottom: 80px;
  }

  @include breakpoint($lg) {
    margin-bottom: 144px;
  }
}

.block {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  column-gap: 16px;

  @include breakpoint($md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint($md) {
    column-gap: 24px;
  }
}
