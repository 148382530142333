@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.root {
  color: $text;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: 'pnum' on, 'lnum' on;

  @include breakpoint($sm) {
    font-size: 16px;
    line-height: 24px;
  }

  strong {
    color: $black;
  }

  p {
    color: $text;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'pnum' on, 'lnum' on;

    @include breakpoint($sm) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  a {
    color: $accent-default;
  }
}

.bottom {
  display: flex;
  justify-content: flex-end;

  a {
    white-space: inherit;
  }
}
