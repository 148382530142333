@import 'src/styles/variables/colors';
@import 'src/styles/variables/effects';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/mixins';

.list {
  display: inline-flex;
  justify-content: center;
  overflow-x: auto;
  width: 100%;
  margin-bottom: 36px;
  border-radius: 14px;
  background-color: $white;
}

.row {
  display: flex;
  padding: 4px;
  border-radius: 12px;
  background-color: #f8f8f8;
}
