@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.root {
  padding: 40px 0;
}

.top_section {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  @include breakpoint($md) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.title {
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  text-align: left;

  @include breakpoint($sm) {
    font-size: 27px;
  }

  @include breakpoint($md) {
    font-size: 32px;
    line-height: 48px;
  }

  @include breakpoint($lg) {
    font-size: 48px;
    line-height: 48px;
  }
}

.price {
  padding-bottom: 32px;
  color: $accent-default;
  font-weight: 700;
  font-size: 23px;
  line-height: 27px;

  @include breakpoint($md) {
    font-size: 32px;
    line-height: 38px;
  }

  @include breakpoint($lg) {
    font-size: 36px;
    line-height: 43px;
  }
}

.text {
  font-size: 16px;
  line-height: 24px;

  h4 {
    color: $secondary;
    font-weight: 400;
  }

  p {
    color: $text;
    font-size: 16px;
    line-height: 24px;
  }

  ul {
    margin-left: 0;
  }

  ul li {
    color: $text;
  }
}

.bottom {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 96px;

  @include breakpoint($sm) {
    margin-bottom: 80px;
  }

  @include breakpoint($lg) {
    margin-bottom: 144px;
  }
}
