@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.root {
  margin-bottom: 96px;

  @include breakpoint($sm) {
    margin-bottom: 80px;
  }

  @include breakpoint($lg) {
    margin-bottom: 144px;
  }
}

.title {
  margin-bottom: 24px;
}

.subtitle {
  margin-bottom: 32px;
  color: $secondary;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  @include breakpoint($lg) {
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
  }
}

.text {
  margin-top: 40px;
  font-size: 14px;
  line-height: 16px;

  @include breakpoint($lg) {
    margin-top: 64px;
  }

  p {
    margin-bottom: 16px;

    @include breakpoint($sm) {
      margin-bottom: 24px;
    }
  }
}

.btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
