@import 'src/styles/variables/colors';
@import 'src/styles/variables/breakpoints';
@import 'src/styles/variables/effects';

.root {
  margin-bottom: 96px;
  padding: 32px 0;
  border-radius: 16px;
  box-shadow: $bs1;

  @include breakpoint($xs) {
    padding: 32px 16px;
  }

  @include breakpoint($sm) {
    margin-bottom: 80px;
    padding: 40px 24px;
  }

  @include breakpoint($lg) {
    margin-bottom: 144px;
    padding: 40px 48px;
  }
}

.content {
  display: grid;
  row-gap: 24px;

  @include breakpoint($sm) {
    row-gap: 32px;
  }

  @include breakpoint($lg) {
    grid-template-columns: 576px 1fr;
    align-items: center;
    column-gap: 40px;
  }
}

.video {
  @include breakpoint($sm) {
    max-width: 576px;
    margin-left: calc((100% - 576px) / 2);
  }
}

.subtitle {
  margin-bottom: 16px;
  color: $accent-default;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  @include breakpoint($xs) {
    line-height: 32px;
  }

  @include breakpoint($lg) {
    margin-bottom: 32px;
    font-size: 22px;
  }
}

.text {
  margin-bottom: 40px;
  color: $secondary;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.subtitle,
.text {
  padding: 0 16px;
  text-align: center;

  @include breakpoint($xs) {
    padding: 0;
  }

  @include breakpoint($lg) {
    text-align: left;
  }
}

.text_block {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include breakpoint($lg) {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
